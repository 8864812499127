import { lazy, Suspense, useEffect, useState } from "react";
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Fab } from "@mui/material";

//Fonts
import "./styles.scss";
import "./fonts/BinomaTrialRegular-R9Kvv.ttf";
import "./fonts/tahoma.ttf";
import { mensaje_whatsapp, numero_whatsapp } from "./constants";

const Inicio = lazy(() => import("./pages/inicio"));
const Productos = lazy(() => import("./pages/producto"));
const Servicios = lazy(() => import("./pages/servicio"));
const Contacto = lazy(() => import("./pages/contacto"));

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Inicio />} />
            <Route path="/productos/:tipo" element={<Productos />} />
            <Route path="/servicios/" element={<Servicios />} />
            <Route path="/contacto" element={<Contacto />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

function Layout() {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh", // Ensure the container takes at least the full viewport height
  };

  return (
    <div style={containerStyle}>
      <Header />
      <Suspense fallback={<h1 style={{ color: "white" }}>Loading...</h1>}>
        <div className="contenedor-principal" style={{ flex: 1 }}>
          <Outlet />
        </div>
      </Suspense>
      <Footer />
      <WhatsAppButton />
    </div>
  );
}

function WhatsAppButton() {
  const [isBlinking, setIsBlinking] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsBlinking((prevBlinking) => !prevBlinking);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://wa.me/${numero_whatsapp}?text=${mensaje_whatsapp}`;
    window.open(whatsappUrl, "_blank");
  };

  const buttonStyle = {
    position: "fixed",
    bottom: 25,
    left: 25,
    backgroundColor: "#25D366",
    color: "#fff",
    boxShadow: isBlinking ? "0 0 20px rgba(37, 211, 172, 1.8)" : "none",
    transition: "background-color 0.5s, color 0.5s, box-shadow 0.5s",
  };

  const handleScroll = () => {
    const footer = document.getElementById("footer");
    const button = document.getElementById("whatsappButton");

    if (footer && button) {
      const visibleHeight = Math.min(
        footer.offsetHeight,
        window.innerHeight - footer.getBoundingClientRect().top
      );

      if (visibleHeight > 0) {
        button.style.bottom = `${visibleHeight + 25}px`;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Fab id="whatsappButton" style={buttonStyle} onClick={handleWhatsAppClick}>
      <WhatsAppIcon />
    </Fab>
  );
}

export default App;
