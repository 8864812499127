import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/Logo.svg";
import Data from "../../assets/data/imageData.json";

const Headder = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElSubMenu, setAnchorElSubMenu] = useState(null);
  const [tiposProductos, setTiposProductos] = useState([]);
  const pages = ["Inicio", "Productos", "Servicios", "Contacto"];

  useEffect(() => {
    const productos = [
      ...new Set(
        Object.values(Data)
          .filter((item) => item.categoria === "PRODUCTOS")
          .map((item) => item.tipo)
      ),
    ];
    setTiposProductos(productos);
  }, []);

  const handleClickMenu = (page, anchor = null) => {
    if (page === "Inicio") {
      window.location.href = "/";
      setAnchorElNav(null);
      setAnchorElSubMenu(null);
      return;
    }
    if (page === "Contacto") {
      window.location.href = "/contacto";
      setAnchorElNav(null);
      setAnchorElSubMenu(null);
      return;
    }
    if (page === "Productos") {
      setAnchorElNav(null);
      setAnchorElSubMenu(anchor ? anchor : anchorElNav);
      return;
    }
    if (page === "Servicios") {
      window.location.href = "/servicios";
    }
  };

  const handleProductoClick = (producto) => {
    const formattedProducto = producto.toLowerCase().replace(/\s+/g, "_");
    window.location.href = `/productos/${formattedProducto}`;
  };

  return (
    <AppBar className="nav-bar" position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <a href="/">
            <img src={Logo} alt="Logo" className="logo-principal" />
          </a>
          <Box sx={{ flexGrow: 1 }} />
          <Box className="box-button-nav">
            {pages.map((page) => (
              <Button
                className="button-nav"
                key={page}
                onClick={(e) => handleClickMenu(page, e.currentTarget)}
              >
                {page}
              </Button>
            ))}
          </Box>
          <IconButton
            className="icon-button-nav"
            size="large"
            onClick={(e) => {
              setAnchorElNav(e.currentTarget);
            }}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            className="menu-nav"
            anchorEl={anchorElNav}
            open={Boolean(anchorElNav)}
            onClose={() => {
              setAnchorElNav(null);
            }}
          >
            {pages.map((page) => (
              <MenuItem className="menu-item-nav" key={page}>
                <Button
                  key={page}
                  sx={{ color: "white", width: "100%" }}
                  onClick={() => {
                    handleClickMenu(page);
                  }}
                >
                  {page}
                </Button>
              </MenuItem>
            ))}
          </Menu>

          {/* Submenu de Producto */}
          <Menu
            className="menu-nav"
            anchorEl={anchorElSubMenu}
            open={Boolean(anchorElSubMenu)}
            onClose={() => {
              setAnchorElSubMenu(null);
            }}
          >
            {tiposProductos.map((producto) => (
              <MenuItem className="menu-item-nav" key={producto}>
                <Button
                  sx={{ color: "white", width: "100%" }}
                  onClick={() => {
                    setAnchorElSubMenu(null);
                    handleProductoClick(producto);
                  }}
                >
                  {producto}
                </Button>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Headder;
