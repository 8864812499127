import Button from "@mui/material/Button";
import React from "react";
import { FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";
import { IoShareSocialSharp } from "react-icons/io5";
import { RiCustomerService2Fill, RiInstagramFill } from "react-icons/ri";
import Logo from "../../assets/images/Logo.svg";
import {
  url_facebook,
  url_instagram,
  url_linkedin,
  url_youtube,
} from "../../constants";

const Footer = () => {
  const handleSocialNetworkClick = (socialNetwork) => {
    window.open(socialNetwork, "_blank");
  };

  return (
    <div id='footer' className="footer-container">
      <a href="/">
        <img src={Logo} alt="Logo" className="logo-footer" />
      </a>
      <div className="siguenos-en">
        <h4 className="footer-titulo">
          <IoShareSocialSharp className="footer-icon-contacto" />
          SIGUENOS
        </h4>
        <div className="container-iconos-social">
          <Button
            className="iconos-social"
            onClick={() => {
              handleSocialNetworkClick(url_facebook);
            }}
          >
            <FaFacebook />
          </Button>
          <Button
            className="iconos-social"
            onClick={() => {
              handleSocialNetworkClick(url_instagram);
            }}
          >
            <RiInstagramFill />
          </Button>
          <Button
            className="iconos-social"
            onClick={() => {
              handleSocialNetworkClick(url_linkedin);
            }}
          >
            <FaLinkedin />
          </Button>
          <Button
            className="iconos-social"
            onClick={() => {
              handleSocialNetworkClick(url_youtube);
            }}
          >
            <FaYoutube />
          </Button>
        </div>
      </div>
      <div className="footer-contacto">
        <h4 className="footer-titulo">
          <RiCustomerService2Fill className="footer-icon-contacto" />
          CONTACTO
        </h4>
        <p style={{ fontFamily: "TahomaFont" }}>
          <strong>Correo:</strong> ventas@qualitycontainers.com.pe
        </p>
        <p style={{ fontFamily: "TahomaFont" }}>
          <strong>Celular:</strong> 906 988 520
        </p>
        <p style={{ fontFamily: "TahomaFont" }}>
          <strong>Dirección:</strong> Av. Nestor Gambeta Km. 14.5, Callao –
          Prov. Const. Del Callao - Prov. Const. Del Callao.
        </p>
      </div>

    </div>
  );
};

export default Footer;
